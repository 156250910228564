/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import ChildPage from "../components/layout/page"
import Section from "../components/layout/section"
import { FixedContainer } from "../components/layout/container"
import { Topic } from "../components/research/topic"

const ResearchPage = ({data}) => {
  const topics = data.allMdx.edges

  const headerImage = data.header.publicURL
  const parallax = {
    image: headerImage
  }

  const breadcrumbs = [
    { "key": "home", "title": "Home", "path": "/" },
    { "key": "research", "title": "Research", "path": "/research" }
  ]

  return (
    <ChildPage 
      title="Research" 
      subtitle="Exploring the Chemistry-Climate Interface"
      breadcrumbs={breadcrumbs}
      parallax={parallax}
    >
      <Section>
        <FixedContainer>
          <h4 className="title is-4">Some Ongoing Projects</h4>          
          <div className="columns is-variable is-4">
            {topics && topics.map(({node}) => {
              const { slug } = node.fields
              const { title, previewImage } = node.frontmatter
              
              return (
                <Topic key={node.id} title={title} image={previewImage} link={slug} />
              )
            })}
          </div>
        </FixedContainer>
      </Section>
    </ChildPage>
  )
}

export default ResearchPage

export const pageQuery = graphql`{
  header: file(relativePath: {eq: "lightning.jpg"}) {
    publicURL
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  allMdx(
    filter: {fields: {type: {eq: "research"}}, frontmatter: {draft: {eq: false}}}
    sort: {fields: [frontmatter___order], order: ASC}
  ) {
    edges {
      node {
        id
        excerpt(pruneLength: 255)
        frontmatter {
          title
          previewImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}
`